import Button from 'components/ui/Button';
import ImageUploader, { Alignment } from 'components/ui/ImageUploader';
import Alert from 'components/ui/Alert';

import { useCmsData } from 'contexts/CmsDataProvider';
import { useAdditionalInfo, useUploadFile } from 'hooks/queries';
import { COMPLETE_PHOTOS_COUNT } from 'constants/constants';
import { trackEvent } from 'services/plausible';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import additionalImageThumbnail from 'assets/thumbnails/front.jpg';

type Props = {
  className?: string;
  goBack: () => any;
  goNext: () => any;
};

const photosContent = [
  {
    slug: 'front',
    alignment: Alignment.Straight,
  },
  {
    slug: 'back',
    alignment: Alignment.Straight,
  },
  {
    slug: 'side_left',
    alignment: Alignment.Side,
  },
  {
    slug: 'side_right',
    alignment: Alignment.Side,
  },
  {
    slug: 'obliquely',
    alignment: Alignment.Oblique,
  },
  {
    slug: 'interior_front',
  },
  {
    slug: 'interior',
  },
  {
    slug: 'speedometer',
  },
  {
    slug: 'trip_computer_engine_on',
  },
];

const Photos: React.FC<Props> = ({ className, goBack, goNext }) => {
  const { t } = useCmsData();
  const { data } = useAdditionalInfo();
  const { uploadFile, removeFile } = useUploadFile();

  return (
    <div className={className}>
      <div className="flex flex-col items-center">
        <div className="grid gap-x-8 grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:max-w-[930px]">
          {photosContent.map((el, i) => (
            <ImageUploader
              key={el.slug}
              title={t(`upload_photo_${el.slug}_title`)}
              image={data?.photos?.[i]?.url}
              thumbnail={require(`../../assets/thumbnails/${el.slug}.jpg`)}
              btnText={t('upload_button')}
              alignment={el.alignment}
              onUpload={() =>
                uploadFile({
                  options: {
                    accept: ['image/*'],
                  },
                  type: 'photo',
                  fileIndex: i,
                })
              }
              onRemove={() =>
                removeFile({
                  type: 'photo',
                  fileIndex: i,
                })
              }
            />
          ))}
        </div>
        <div className="mb-8 w-full lg:max-w-[930px]">
          <Alert
            className="mx-4"
            title={t('photos_info_banner_title')}
            description={t('photos_info_banner_desc')}
          />
        </div>
        <div className="grid gap-x-8 grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:max-w-[930px]">
          {data?.additionalPhotos?.map((el, i) => (
            <ImageUploader
              key={el.id}
              title={t('upload_additional_photo_title')}
              image={el.url}
              thumbnail={additionalImageThumbnail}
              btnText={t('upload_button')}
              alignment={Alignment.Add}
              onUpload={() =>
                uploadFile({
                  options: {
                    accept: ['image/*'],
                  },
                  type: 'additionalPhoto',
                })
              }
              onRemove={() =>
                removeFile({
                  type: 'additionalPhoto',
                  fileIndex: i,
                })
              }
            />
          ))}
          <ImageUploader
            title={t('upload_additional_photo_title')}
            thumbnail={additionalImageThumbnail}
            btnText={t('upload_button')}
            alignment={Alignment.Add}
            onUpload={() =>
              uploadFile({
                options: {
                  accept: ['image/*'],
                },
                type: 'additionalPhoto',
              })
            }
          />
        </div>
      </div>
      <div className="mt-6 pb-20 flex flex-col gap-y-3 sm:flex-row justify-between">
        <Button
          className="!bg-background !border !border-dark"
          label={t('photos_prev_btn')}
          iconSlot="start"
          icon={<ArrowLeftIcon className="mr-1 size-6" />}
          onClick={goBack}
        />
        <Button
          label={t('photos_next_btn')}
          onClick={() => {
            trackEvent('as24-step5-upload-pictures');
            goNext();
          }}
          isDisabled={
            data?.photos.filter((el) => el.url)?.length !==
            COMPLETE_PHOTOS_COUNT
          }
        />
      </div>
    </div>
  );
};

export default Photos;
