import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';

import { useCmsData } from 'contexts/CmsDataProvider';

type Props = {
  isOpen: boolean;
  onDismiss: () => any;
};

const LowBidHintModal: React.FC<Props> = ({ isOpen, onDismiss }) => {
  const { t } = useCmsData();

  return (
    <Modal
      isOpen={isOpen}
      title={t('dashboard_low_bid_modal_title')}
      onDismiss={onDismiss}
      content={
        <div>
          <ReactMarkdown
            className="markdown"
            rehypePlugins={[rehypeRaw]}
            children={t('dashboard_low_bid_modal_content')}
          />
        </div>
      }
      footer={
        <Button
          className="w-full"
          label={t('dashboard_low_bid_modal_confirm_btn')}
          icon={null}
          outline
          onClick={onDismiss}
        />
      }
    />
  );
};

export default LowBidHintModal;
