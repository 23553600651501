import ReactCountdown from 'react-countdown';

import { useCmsData } from 'contexts/CmsDataProvider';

type Props = {
  date: Date;
  onComplete?: () => any;
};

const Countdown: React.FC<Props> = ({ date, onComplete }) => {
  const { t } = useCmsData();

  return (
    <ReactCountdown
      key={date.toString()}
      date={date}
      intervalDelay={0}
      renderer={({ days, hours, minutes, seconds }) => {
        return days > 0
          ? `${days} ${t('days')} ${hours} ${t('hours')}`
          : hours > 0
          ? `${hours} ${t('hours')} ${minutes} ${t('minutes')}`
          : `${minutes} ${t('minutes')} ${seconds} ${t('seconds')}`;
      }}
      onComplete={onComplete}
    />
  );
};

export default Countdown;
