import axios from './axios';
import validator from 'validator';
import _ from 'lodash';

import type { Locale } from 'types/types';

export const numberWithSeparator = (number: number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '’');

export const camelizeObject = (obj: Object) =>
  _.transform(obj, (acc: any, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    acc[camelKey] = _.isObject(value) ? camelizeObject(value) : value;
  });

export const isPdfFile = async (url: string) => {
  try {
    const response = await axios.head(url);
    const contentType = response.headers['content-type'];

    return contentType.includes('application/pdf');
  } catch (error) {
    console.error('Error fetching file metadata:', error);
    return false;
  }
};

export const formatNumber = (
  number: number,
  {
    price = false,
    hiddenFloat = false,
  }: {
    price?: boolean;
    hiddenFloat?: boolean;
  }
) => {
  if (!number && number !== 0) {
    return '';
  }

  let result = new Intl.NumberFormat('de-ch', {
    maximumFractionDigits: 2,
  }).format(number);

  if (hiddenFloat) {
    result =
      new Intl.NumberFormat('de-ch', {
        maximumFractionDigits: 0,
      }).format(number) + '.–';
  }
  if (price) {
    result = 'CHF ' + result;
  }

  return result;
};

interface ValidationErrors {
  [key: string]: boolean;
}

export const validateInput = (
  name: string,
  value: string
): ValidationErrors => {
  const errors: ValidationErrors = {};

  switch (name) {
    case 'email':
      if (!validator.isEmail(value)) {
        errors.emailInvalid = true;
      } else {
        errors.emailInvalid = false;
      }
      break;
    case 'phone':
      if (!validator.isMobilePhone(value, 'any')) {
        errors.phoneInvalid = true;
      } else {
        errors.phoneInvalid = false;
      }
      break;
    default:
      break;
  }

  return errors;
};

export const getAttributeTranslation = (
  {
    key,
    value,
  }: {
    key: string;
    value: number;
  },
  locale: Locale,
  attributes: any
) =>
  _.find(
    attributes,
    (el) => el.type === key && el.translations.key === value.toString()
  )?.translations[locale];

export const focusNextField = (
  nextRef: any,
  type: 'select' | 'input' = 'select'
) => {
  const nextElement = nextRef.current;

  if (nextElement) {
    nextElement!.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
    setTimeout(
      () => {
        switch (type) {
          case 'select': {
            const button = nextElement.querySelector('button');
            if (button) {
              button.click();
            }
            break;
          }
          case 'input': {
            nextElement.focus();
            break;
          }
          default:
            break;
        }
      },
      type === 'select' ? 400 : 150
    );
  }
};
