import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';

import './index.css';
import './styles/globals.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import CmsDataProvider from 'contexts/CmsDataProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <CmsDataProvider>
      <App />
    </CmsDataProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
