import axios from 'axios';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}/api`,
});

export const cad2Axios = axios.create({
  baseURL: `${process.env.REACT_APP_CAD2_API_DOMAIN}`,
});

export default axiosClient;
