import { Tooltip as TooltipComponent, TooltipProps } from 'antd';
import cx from 'classnames';

type Props = {
  title: string;
  className?: string;
  children: React.ReactNode;
} & TooltipProps;

const Tooltip: React.FC<Props> = ({ title, className, children, ...props }) => {
  return (
    <TooltipComponent
      className={cx('cursor-pointer', className)}
      title={title}
      zIndex={30}
      overlayInnerStyle={{
        color: 'var(--color-dark)',
        padding: 24,
        fontSize: 16,
      }}
      {...props}
    >
      {children}
    </TooltipComponent>
  );
};

export default Tooltip;
