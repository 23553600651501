import axios, { cad2Axios } from 'utils/axios';

import * as routes from 'api/routes';
import { camelizeObject } from 'utils/utils';

import { DeclineReason } from 'types/enum';
import type {
  AdditionalInfo,
  AuctionMetrics,
  DealerContacts,
} from 'types/types';

export const getCmsData = (widgetId: string) =>
  axios
    .post(routes.GET_CMS_DATA, JSON.stringify(widgetId))
    .then((res) => res.data);

export const getAttributes = () =>
  axios.get(routes.GET_ATTRIBUTES).then((res) => res.data);

export const getAdditionalInfo = (submissionId: string) =>
  axios
    .post(routes.GET_ADDITIONAL_INFO, JSON.stringify(submissionId))
    .then(
      (res) =>
        (res.data.vehicleInfo
          ? { ...camelizeObject({ ...res.data }), answers: res.data.answers }
          : res.data) as AdditionalInfo
    );

export const editMileage = ({
  submissionId,
  editedMileage,
}: {
  submissionId: string;
  editedMileage: number;
}) =>
  axios.post(
    routes.EDIT_MILEAGE,
    JSON.stringify({
      submission_id: submissionId,
      edited_mileage: editedMileage,
    })
  );

export const getDealerContacts = (submissionId: string) =>
  cad2Axios
    .post(routes.GET_DEALER_CONTACTS, {
      submissionId,
    })
    .then((res) => res.data as DealerContacts);

export const restartAuction = ({
  submissionId,
  desiredPrice,
}: {
  submissionId: string;
  desiredPrice: AdditionalInfo['desiredPrice'];
}) =>
  axios.post(
    routes.RESTART_AUCTION,
    JSON.stringify({
      submission_id: submissionId,
      desired_price: desiredPrice,
    })
  );

export const saveChanges = (data: any) =>
  axios.post(routes.SAVE_CHANGES, JSON.stringify(data));

export const getAuctionMetrics = (submissionId: string) =>
  cad2Axios
    .post(routes.GET_AUCTION_METRICS, {
      submissionId,
    })
    .then((res) => res.data as AuctionMetrics);

export const acceptBid = (submissionId: string) =>
  cad2Axios.post(routes.ACCEPT_BID, { submissionId });

export const declineBid = (data: {
  declineReasons: { [key in DeclineReason]?: boolean };
  submissionId: string;
  counterOffer?: AdditionalInfo['auction']['counterOffer'];
}) => cad2Axios.post(routes.DECLINE_BID, data);
