import { useCmsData } from 'contexts/CmsDataProvider';

const Header: React.FC = () => {
  const { t } = useCmsData();

  return (
    <header className="fixed top-0 left-0 w-full flex items-center justify-between p-6 border-b border-border-light bg-background transition-all z-30">
      <h1 className="text-xl font-bold">{t('header_title')}</h1>
    </header>
  );
};

export default Header;
