import { useState } from 'react';
import { useMutation } from 'react-query';

import Modal from 'components/ui/Modal';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';

import { restartAuction } from 'api/api';
import { useCmsData } from 'contexts/CmsDataProvider';
import { useAdditionalInfo, useAttributes } from 'hooks/queries';
import { formatNumber, getAttributeTranslation } from 'utils/utils';

import type { AdditionalInfo } from 'types/types';

import { ReactComponent as BulbIcon } from 'assets/icons/bulb.svg';

type Props = {
  isOpen: boolean;
  onDismiss: () => any;
};

const AcceptModal: React.FC<Props> = ({ isOpen, onDismiss }) => {
  const [newMinPrice, setNewMinPrice] =
    useState<AdditionalInfo['desiredPrice']>(null);

  const { t, locale } = useCmsData();
  const {
    data: additionalInfo,
    submissionId,
    refetch,
    isFetching,
  } = useAdditionalInfo();
  const {
    vehicleInfo: { make, model, exactModel, body },
    desiredPrice,
  } = additionalInfo!;
  const { data: attributes } = useAttributes();

  const { mutate, isLoading } = useMutation(
    'restartAuction',
    () =>
      restartAuction({
        submissionId: submissionId!,
        desiredPrice: newMinPrice,
      }),
    {
      onSuccess: async () => {
        await refetch();
        onDismiss();
      },
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('dashboard_restart_modal_title')}
      onDismiss={onDismiss}
      content={
        <div className="pb-[126px]">
          <div>
            {make} {model} {exactModel}{' '}
            {getAttributeTranslation(
              { key: 'bodyType', value: body },
              locale,
              attributes
            )}
          </div>

          <div>
            {t('dashboard_restart_modal_prev_min_price').replace(
              '{minPrice}',
              formatNumber(desiredPrice || 0, {
                price: false,
                hiddenFloat: false,
              })
            )}
          </div>

          <p className="my-9">{t('dashboard_restart_modal_content')}</p>

          <div>
            <div className="flex flex-col items-start sm:flex-row sm:items-end gap-3">
              <Input
                className="mt-2 w-full sm:flex-1"
                label={t('dashboard_restart_modal_new_min_price_label')}
                placeholder={t(
                  'dashboard_restart_modal_new_min_price_placeholder'
                )}
                value={newMinPrice || ''}
                maxLength={9}
                type="number"
                onChange={(newMinPrice) =>
                  setNewMinPrice(newMinPrice as number)
                }
                autoComplete="off"
              />
              <div className="flex gap-x-3">
                <Button
                  className="w-[72px]"
                  label="-5%"
                  icon={null}
                  onClick={() =>
                    setNewMinPrice(Math.round((desiredPrice || 0) * 0.95))
                  }
                  outline
                />
                <Button
                  className="w-[72px]"
                  label="-10%"
                  icon={null}
                  onClick={() =>
                    setNewMinPrice(Math.round((desiredPrice || 0) * 0.9))
                  }
                  outline
                />
              </div>
            </div>

            <div className="mt-3 flex items-center gap-x-2">
              <BulbIcon className="shrink-0 size-6" />
              {t('dashboard_restart_modal_new_min_price_hint')}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex flex-col sm:flex-row items-center gap-3">
          <Button
            className="w-full sm:flex-1"
            label={t('dashboard_restart_modal_confirm_btn')}
            icon={null}
            onClick={mutate}
            isLoading={isLoading || isFetching}
            isDisabled={!newMinPrice}
          />
          <Button
            className="w-full sm:w-fit"
            label={t('dashboard_restart_modal_cancel_btn')}
            icon={null}
            onClick={onDismiss}
            outline
          />
        </div>
      }
    />
  );
};

export default AcceptModal;
