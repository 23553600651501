import { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useMeasure from 'react-use-measure';
import cx from 'classnames';

import Fade from 'components/ui/Fade';
import AnimateNumber from 'components/ui/AnimatedNumber';

import { numberWithSeparator } from 'utils/utils';
import { useCmsData } from 'contexts/CmsDataProvider';

import { ReactComponent as MarkerIcon } from 'assets/icons/marker.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

type Props = {
  className?: string;
  min: number;
  max: number;
  currentPrice: number | null;
};

const markerWidth = 28;

const PriceRange: React.FC<Props> = ({ className, currentPrice, min, max }) => {
  const [wrapperRef, wrapperBounds] = useMeasure();
  const [mainRangeRef, mainRangeBounds] = useMeasure();
  const { t } = useCmsData();

  const markerXPosition = useMemo(() => {
    const wrapperWidth = wrapperBounds.width;
    const mainRangeWidth = mainRangeBounds.width;

    if (!currentPrice || !wrapperWidth || !mainRangeWidth) {
      return 0;
    }

    const padding = (wrapperWidth - mainRangeWidth) / 2;
    const markerOffset = markerWidth / 2;

    if (currentPrice < min) {
      return (currentPrice * padding) / min - markerOffset;
    }

    if (currentPrice >= min && currentPrice <= max) {
      return (
        padding +
        ((currentPrice - min) * mainRangeWidth) / (max - min) -
        markerOffset
      );
    }

    if (currentPrice > max && currentPrice <= max + min) {
      return (
        padding +
        mainRangeWidth +
        ((currentPrice - max) * padding) / min -
        markerOffset
      );
    }

    return wrapperWidth - markerOffset;
  }, [min, max, currentPrice, wrapperBounds, mainRangeBounds]);

  const renderRangeHintBoxContent = () => {
    if (!currentPrice) {
      return { icon: null, description: null };
    }

    if (currentPrice <= min + (max - min) / 2) {
      return {
        icon: <SuccessIcon className="size-6 text-secondary" />,
        description: t('min_price_range_box_description_optimal'),
      };
    }

    if (currentPrice > min + (max - min) / 2 && currentPrice <= max) {
      return {
        icon: <InfoIcon className="size-6 text-tertiary" />,
        description: t('min_price_range_box_description_reasonable'),
      };
    }

    return {
      icon: <WarningIcon className="size-6 text-danger" />,
      description: t('min_price_range_box_description_too_high'),
    };
  };

  return (
    <div className={cx('pt-[50px] pb-[25px]', className)}>
      <div
        ref={wrapperRef}
        className="relative w-full max-w-[312px] mx-auto flex items-center justify-center"
      >
        <div
          ref={mainRangeRef}
          className="relative flex items-center justify-between w-[130px] sm:w-[200px] h-6 rounded-full bg-gradient-to-r from-secondary-shade to-secondary-tint"
        >
          <div className="relative w-[1px] h-[37px] -translate-x-[1px] -translate-y-[6.5px] bg-dark/20">
            <div className="absolute -top-[30px] left-0 -translate-x-1/2 text-xl font-bold leading-[25px]">
              CHF
              <span className="ml-1">{numberWithSeparator(min)}</span>
            </div>
          </div>
          <div className="relative w-[1px] h-[37px] translate-x-[1px] -translate-y-[6.5px] bg-dark/20">
            <div className="absolute -top-[30px] left-0 -translate-x-1/2 text-xl font-bold leading-[25px]">
              CHF
              <span className="ml-1">{numberWithSeparator(max)}</span>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 bg-[linear-gradient(90deg,transparent,var(--color-secondary-tint)_32%,var(--color-secondary-shade)_68%,transparent)] opacity-[0.24] w-full h-6 z-[1]" />
        {currentPrice && (
          <motion.div
            className="absolute bottom-full"
            initial={{ opacity: 0 }}
            animate={{ left: markerXPosition, opacity: 1 }}
          >
            <MarkerIcon
              className="text-secondary"
              style={{ width: markerWidth }}
            />
            <div className="absolute left-1/2 -translate-x-1/2 w-[2.5px] h-[80px] bg-secondary-light" />
          </motion.div>
        )}
      </div>
      <AnimatePresence>
        {currentPrice && (
          <Fade
            initial={{ marginTop: 0, height: 0 }}
            animate={{ marginTop: 56, height: 'auto' }}
            exit={{ marginTop: 0, height: 0 }}
          >
            <div
              className={cx(
                'flex gap-x-4 p-4 pt-[14px] border-t-2 rounded transition-colors duration-300',
                {
                  'bg-secondary-light border-secondary':
                    currentPrice <= min + (max - min) / 2,
                  'bg-highlight border-tertiary':
                    currentPrice > min + (max - min) / 2 && currentPrice <= max,
                  'bg-danger-tint border-danger': currentPrice > max,
                }
              )}
            >
              <div>{renderRangeHintBoxContent().icon}</div>
              <div>
                <div className="text-2xl font-bold">
                  CHF <AnimateNumber value={currentPrice} />
                </div>
                <p>{renderRangeHintBoxContent().description}</p>
              </div>
            </div>
          </Fade>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PriceRange;
