import Plausible from 'plausible-tracker';
import { IS_PRODUCTION } from 'constants/constants';

const plausible = IS_PRODUCTION
  ? Plausible({
      domain: 'autoscout24.ch',
      trackLocalhost: false,
    })
  : null;

export const trackEvent = (event: string) => plausible?.trackEvent(event);
