import cx from 'classnames';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

export enum AlertType {
  Default,
  Warning,
  Success,
}

type Props = {
  className?: string;
  title: string;
  description: string;
  type?: AlertType;
};

const Alert: React.FC<Props> = ({
  className,
  title,
  description,
  type = AlertType.Default,
}) => {
  return (
    <div
      className={cx(
        'flex p-4 w-full rounded border-l-4 transition-colors',
        {
          'bg-highlight border-tertiary': type === AlertType.Default,
          'bg-warning-tint border-warning': type === AlertType.Warning,
          'bg-secondary-light border-secondary': type === AlertType.Success,
        },
        className
      )}
    >
      <div>
        <InfoIcon
          className={cx('size-6 transition-colors', {
            'text-tertiary': type === AlertType.Default,
            'text-warning': type === AlertType.Warning,
            'text-secondary': type === AlertType.Success,
          })}
        />
      </div>
      <div className="ml-4">
        <h3 className="font-bold">{title}</h3>
        <p className="font-medium">{description}</p>
      </div>
    </div>
  );
};

export default Alert;
