import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { getCmsData } from 'api/api';

import type { Option } from 'components/ui/Dropdown';
import type { Locale } from 'types/types';

export type Translations = {
  id: string;
  locale: Locale;
  data: {
    content: ({ id: number; key: string } & { [key in Locale]: string })[];
    generalTextStrings: ({ id: number; key: string } & {
      [key in Locale]: string;
    })[];
    questions: ({ id: number; key: string; optionalAnswer: boolean } & {
      [key in Locale]: string;
    })[];
  } | null;
  isLoading: boolean;
  t: (key: string, noPlaceholder?: boolean) => string;
  getOptions: (field: string) => Option[];
};

const defaultValue: Translations = {
  id: '',
  locale: 'de',
  data: null,
  isLoading: true,
  t: () => '__empty__',
  getOptions: () => [],
};

const CmsDataContext = createContext(defaultValue);

export const useCmsData = () => useContext(CmsDataContext);

export const CmsDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [id] = useState('as24');
  const [locale, setLocale] = useState(defaultValue.locale);
  const [data, setData] = useState<Translations['data']>(defaultValue.data);

  const { isLoading } = useQuery('cmsData', () => getCmsData(id), {
    onSuccess: (fullCmsData: any) => {
      setData({
        content: [
          ...fullCmsData.upload_area,
          ...fullCmsData.general_text_strings.filter(
            (el: any) => el.key === 'km' || el.key === 'hp'
          ),
        ],
        generalTextStrings: fullCmsData.general_text_strings,
        questions: fullCmsData.upload_area_questions,
      });
    },
    onError: () =>
      alert(
        'Unfortunately something went wrong! Please try again later or contact us.'
      ),
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');

    if (['de', 'en', 'fr', 'it'].indexOf(lang || '') !== -1) {
      setLocale(lang as Locale);
    }
  }, []);

  const t = (key: string, ignorePlaceholder = false) => {
    if (!data) {
      return '';
    }

    const value = [
      _.find(data.content, { key })?.[locale],
      _.find(data.generalTextStrings, { key })?.[locale],
      _.find(data.questions, { key })?.[locale],
    ].filter((el) => el || el === null)[0];

    return value
      ? value
      : value === null || ignorePlaceholder
      ? ''
      : '__empty__';
  };

  const getOptions = (key: string) => {
    if (!data) {
      return [];
    }

    const prefix = `${key}_option_`;

    return data.content
      .filter((el) => el.key.startsWith(prefix))
      .map((item) => ({
        name: item[locale],
        value: item.key.split(prefix).pop() || '',
      }));
  };

  return (
    <CmsDataContext.Provider
      value={{
        id,
        locale,
        data,
        t,
        isLoading,
        getOptions,
      }}
    >
      {children}
    </CmsDataContext.Provider>
  );
};

export default CmsDataProvider;
