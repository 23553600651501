import { Field, Label, Radio, RadioGroup } from '@headlessui/react';

import Tooltip from 'components/ui/Tooltip';

import { useCmsData } from 'contexts/CmsDataProvider';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = {
  label: string;
  value: boolean | null;
  tooltip?: string;
  isOptional?: boolean;
  onChange: (val: boolean | null) => any;
};

const Question: React.FC<Props> = ({
  label,
  value,
  tooltip,
  onChange,
  isOptional = false,
}) => {
  const { t } = useCmsData();
  const options = [
    { label: t('questions_answer_yes'), value: true },
    { label: t('questions_answer_no'), value: false },
    ...(isOptional
      ? [{ label: t('questions_answer_not_available'), value: null }]
      : []),
  ];

  return (
    <div className="flex flex-col justify-between">
      <div className="min-h-10 mb-2 font-bold">
        {label}
        {tooltip && (
          <Tooltip title={tooltip} color="var(--color-background)">
            <InfoIcon className="inline ml-1 size-5 cursor-pointer" />
          </Tooltip>
        )}
      </div>
      <RadioGroup
        className="border border-light rounded"
        value={value !== undefined ? value : ''}
        onChange={(val) => onChange(typeof val === 'boolean' ? val : null)}
      >
        {options.map((option, i) => (
          <Field
            key={i}
            className="p-4 flex gap-x-2 border-b border-light last:border-none cursor-pointer"
            onClick={() => onChange(option.value)}
          >
            <Radio
              value={option.value}
              className="mt-1 group flex flex-shrink-0 size-4 items-center justify-center rounded-full border bg-background data-[checked]:bg-dark outline-none pointer-events-none"
            >
              <span className="invisible size-1.5 rounded-full bg-background group-data-[checked]:visible" />
            </Radio>
            <Label className="pointer-events-none">{option.label}</Label>
          </Field>
        ))}
      </RadioGroup>
    </div>
  );
};

export default Question;
