import parse from 'html-react-parser';
import { useWindowSize } from '@uidotdev/usehooks';

import Tooltip from 'components/ui/Tooltip';

import { useAttributes } from 'hooks/queries';
import { useCmsData } from 'contexts/CmsDataProvider';
import { getAttributeTranslation, numberWithSeparator } from 'utils/utils';

import type { AdditionalInfo } from 'types/types';

type Props = {
  className?: string;
  vehicleInfo: AdditionalInfo['vehicleInfo'];
};

const HeadingSection: React.FC<Props> = ({ className, vehicleInfo }) => {
  const { width } = useWindowSize();
  const { t, locale } = useCmsData();
  const { data: attributes } = useAttributes();
  const {
    make,
    model,
    exactModel,
    firstRegistrationMonth,
    firstRegistrationYear,
    mileage,
    editedMileage,
    body,
  } = vehicleInfo;

  return (
    <div className={className}>
      <h1 className="text-2xl sm:text-[32px] leading-[40px] font-bold">
        {t('additional_info_title')}
      </h1>
      <div className="mt-2 flex flex-col justify-between gap-x-6 gap-y-3 md:flex-row">
        <p className="w-full lg:max-w-[640px] whitespace-pre-wrap">
          {parse(t('additional_info_description'))}
        </p>
        <div className="w-full lg:max-w-[640px] md:text-right">
          <div className="font-bold">
            {make} {model} {exactModel}{' '}
            {getAttributeTranslation(
              { key: 'bodyType', value: body },
              locale,
              attributes
            )}
          </div>
          <div>
            {(firstRegistrationMonth + 1).toString().padStart(2, '0')} /{' '}
            {firstRegistrationYear} •{' '}
            <Tooltip
              className="underline"
              title={t('additional_info_mileage_hint')}
              placement={width && width >= 640 ? 'bottomRight' : 'bottom'}
              color="var(--color-background)"
              rootClassName="sm:max-w-[320px]"
            >
              {numberWithSeparator(editedMileage || mileage)} {t('km')}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingSection;
