import * as filestack from 'filestack-js';

const client = filestack.init(process.env.REACT_APP_FILESTACK_API_KEY!);

export const uploadFile = (options: filestack.PickerOptions) =>
  client
    .picker({
      disableTransformer: true,
      fromSources: ['local_file_system'],
      maxFiles: 1,
      uploadInBackground: false,
      ...options,
    })
    .open();
