import { useEffect } from 'react';
import { motion, useSpring, useTransform } from 'framer-motion';

import { numberWithSeparator } from 'utils/utils';

type Props = {
  value: number;
};

const AnimateNumber: React.FC<Props> = ({ value }) => {
  const spring = useSpring(value, { mass: 0.15, stiffness: 200, damping: 11 });
  const display = useTransform(spring, (current) =>
    numberWithSeparator(Math.round(current))
  );

  useEffect(() => {
    spring.set(value);
  }, [spring, value]);

  return <motion.span>{display}</motion.span>;
};

export default AnimateNumber;
