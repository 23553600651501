import { CloudArrowUpIcon, XCircleIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

type Props = {
  title: string;
  description?: string;
  image?: string;
  thumbnail: string;
  btnText: string;
  onUpload: () => any;
  onRemove?: () => void;
  alignment?: Alignment;
};

export enum Alignment {
  Oblique = 'Oblique',
  Side = 'Side',
  Straight = 'Straight',
  Add = 'Add',
}

const ImageUploader: React.FC<Props> = ({
  title,
  description,
  image,
  thumbnail,
  btnText,
  onUpload,
  onRemove,
  alignment,
}) => {
  const renderAlignmentLine = () => {
    if (alignment === Alignment.Add) {
      return (
        <>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60px] h-3 bg-tertiary rounded-lg" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60px] h-3 bg-tertiary rounded-lg rotate-90" />
        </>
      );
    }

    return (
      <div
        className={cx('absolute w-4/5 h-3 bg-tertiary rounded-lg', {
          'top-[124px] left-[50px] rotate-[-31deg]':
            alignment === Alignment.Oblique,
          'top-[120px] left-1/2 -translate-x-1/2': alignment === Alignment.Side,
          'top-[136px] left-1/2 -translate-x-1/2':
            alignment === Alignment.Straight,
        })}
      />
    );
  };

  return (
    <div className="mb-7 mx-4 w-52 text-center rounded-lg shadow">
      <div className="p-4 text-sm cursor-pointer" onClick={onUpload}>
        <div className="relative">
          <img
            className={cx(
              'mb-3 size-44 object-cover rounded-lg transition-opacity',
              image ? 'opacity-100' : 'opacity-70'
            )}
            src={image ? image : thumbnail}
            alt=""
          />
          {alignment && !image && renderAlignmentLine()}
        </div>
        <h3 className="font-bold">{title}</h3>
        {description && <p className="mb-0 text-border">{description}</p>}
      </div>
      <div
        className={cx(
          'flex w-full p-4 border-t border-border-light text-sm font-bold',
          image && onRemove ? 'justify-between' : 'justify-center'
        )}
      >
        <div
          className="group inline-flex items-center cursor-pointer hover:text-tertiary"
          onClick={onUpload}
        >
          <CloudArrowUpIcon className="mr-1.5 size-6 text-border group-hover:text-tertiary" />
          {btnText}
        </div>

        {image && onRemove && (
          <div className="flex-initial cursor-pointer" onClick={onRemove}>
            <XCircleIcon className="size-6 text-border hover:text-danger" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
