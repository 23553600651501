import { useState, useEffect } from 'react';
import * as pdfjs from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

const usePdfThumbnail = (pdfUrl: string, { scale = 1, enabled = true }) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadingTask = pdfjs.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context!,
          viewport: viewport,
        };
        await page.render(renderContext).promise;

        const thumbnailUrl = canvas.toDataURL('image/png');
        setThumbnail(thumbnailUrl);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    if (pdfUrl && enabled) {
      loadPdf();
    }
  }, [pdfUrl, scale, enabled]);

  return { thumbnail };
};

export default usePdfThumbnail;
