import Footer from 'components/Footer';

import { useCmsData } from 'contexts/CmsDataProvider';

const NotFound: React.FC = () => {
  const { t } = useCmsData();

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className="mt-[78px] flex flex-col items-center justify-center flex-1 p-10">
        <h1 className="text-2xl sm:text-[32px] font-bold text-center">
          {t('404_title')}
        </h1>
        <p className="mt-1 sm:mt-2 sm:max-w-[500px] text-center">
          {t('404_desc')}
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
