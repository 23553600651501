import cx from 'classnames';

import { useCmsData } from 'contexts/CmsDataProvider';

import { ReactComponent as SupportIcon } from 'assets/icons/support.svg';

type Props = { className?: string };

const Footer: React.FC<Props> = ({ className }) => {
  const { t } = useCmsData();

  return (
    <footer className={cx('bg-highlight-tint-2 px-6 py-12', className)}>
      <h3 className="text-xl font-bold">{t('footer_title')}</h3>
      <div className="flex flex-col sm:flex-row items-start justify-center mt-12 gap-x-12 gap-y-8">
        <div className="flex justify-center sm:w-1/3 sm:max-w-[310px]">
          <SupportIcon className="size-[72px]" />
        </div>
        <div className="sm:w-1/3 sm:max-w-[310px]">
          <h4 className="font-bold">{t('footer_col2_title')}</h4>
          <p className="mt-3 whitespace-pre-wrap">
            {t('footer_col2_description')}
          </p>
        </div>
        <div className="sm:w-1/3 sm:max-w-[310px]">
          <h4 className="font-bold">{t('footer_col3_title')}</h4>
          <p className="mt-3 whitespace-pre-wrap">
            {t('footer_col3_description')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
