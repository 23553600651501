import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import parse from 'html-react-parser';
import cx from 'classnames';

import Modal from 'components/ui/Modal';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';

import { declineBid } from 'api/api';
import { useCmsData } from 'contexts/CmsDataProvider';
import { useAdditionalInfo, useAttributes } from 'hooks/queries';
import {
  focusNextField,
  formatNumber,
  getAttributeTranslation,
} from 'utils/utils';
import { DeclineReason } from 'types/enum';

type Props = {
  isOpen: boolean;
  onDismiss: () => any;
};

const DeclineModal: React.FC<Props> = ({ isOpen, onDismiss }) => {
  const [counterOffer, setCounterOffer] = useState<number | undefined>(
    undefined
  );
  const [selectedReasons, setSelectedReasons] = useState<{
    [key in DeclineReason]?: boolean;
  }>({});

  const counterOfferRef = useRef(null);
  const { t, locale } = useCmsData();
  const {
    data: additionalInfo,
    submissionId,
    refetch,
    isFetching,
  } = useAdditionalInfo();
  const {
    vehicleInfo: { make, model, exactModel, body },
    auction: { highestBid },
  } = additionalInfo!;
  const { data: attributes } = useAttributes();

  useEffect(() => {
    setCounterOffer(undefined);
    setSelectedReasons({});
  }, [isOpen]);

  const { mutate, isLoading } = useMutation(
    'declineBid',
    () =>
      declineBid({
        submissionId: submissionId!,
        declineReasons: selectedReasons,
        counterOffer: counterOffer,
      }),
    {
      onSuccess: async () => {
        await refetch();
        onDismiss();
      },
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  const onToggleReason = (reasonKey: DeclineReason) => {
    const updSelectedReasons = { ...selectedReasons };

    if (selectedReasons[reasonKey]) {
      delete updSelectedReasons[reasonKey];
      setSelectedReasons(updSelectedReasons);
    } else {
      updSelectedReasons[reasonKey] = true;
      setSelectedReasons(updSelectedReasons);

      setTimeout(() => {
        if (reasonKey === DeclineReason.LowBid) {
          focusNextField(counterOfferRef, 'input');
        }
      }, 50);
    }
  };

  const reasons = useMemo(
    () => [
      {
        name: t('dashboard_decline_modal_reason_low_bid'),
        value: DeclineReason.LowBid,
      },
      {
        name: t('dashboard_decline_modal_reason_trade_in_canceled'),
        value: DeclineReason.TradeInCanceled,
      },
      {
        name: t('dashboard_decline_modal_reason_other'),
        value: DeclineReason.Other,
      },
    ],
    [t]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('dashboard_decline_modal_title')}
      onDismiss={onDismiss}
      content={
        <>
          <div>
            {make} {model} {exactModel}{' '}
            {getAttributeTranslation(
              { key: 'bodyType', value: body },
              locale,
              attributes
            )}
          </div>

          <div className="mt-4 p-6 flex items-center justify-center flex-col border border-light rounded">
            <p>{t('dashboard_decline_modal_highest_bid')}</p>
            <div className="mt-1 font-bold text-[32px] leading-[40px]">
              {formatNumber(highestBid || 0, {
                price: true,
                hiddenFloat: false,
              })}
            </div>
          </div>

          <div className="mt-6">
            <div>{t('dashboard_decline_modal_reasons_title')}</div>
            <div className="mt-4 flex flex-wrap gap-4">
              {reasons.map((reason) => (
                <div
                  key={reason.value}
                  className={cx(
                    'py-1.5 px-4 w-fit text-sm rounded-full bg-light font-bold cursor-pointer select-none',
                    {
                      'bg-primary scale-[1.1] mx-1.5':
                        selectedReasons?.[reason.value],
                    }
                  )}
                  style={{
                    transitionProperty: 'margin, transform',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    transitionDuration: '150ms',
                  }}
                  onClick={() => onToggleReason(reason.value)}
                >
                  {reason.name}
                </div>
              ))}
            </div>
          </div>

          <div className="mt-10">
            {selectedReasons.lowPrice && (
              <>
                <div className="font-bold">
                  {t('dashboard_decline_modal_counter_offer_title')}
                </div>
                <p>{t('dashboard_decline_modal_counter_offer_desc')}</p>
                <Input
                  ref={counterOfferRef}
                  className="mt-2 sm:w-1/2"
                  label={t('dashboard_decline_modal_counter_offer_input_label')}
                  placeholder={t(
                    'dashboard_decline_modal_counter_offer_input_placeholder'
                  )}
                  value={counterOffer || ''}
                  maxLength={9}
                  type="number"
                  onChange={(counterOffer) =>
                    setCounterOffer(counterOffer as number)
                  }
                  autoComplete="off"
                  invalid={!!(counterOffer && counterOffer <= highestBid)}
                  invalidMsg={t(
                    'dashboard_decline_modal_counter_offer_input_invalid_msg'
                  )}
                />
              </>
            )}

            <p className="pt-[50px] html-md">
              {parse(t('dashboard_decline_modal_hint'))}
            </p>
          </div>
        </>
      }
      footer={
        <div className="flex items-center gap-x-3">
          <Button
            className="w-1/2"
            label={t('dashboard_decline_modal_confirm_btn')}
            icon={null}
            onClick={mutate}
            isLoading={isLoading || isFetching}
            isDisabled={
              !Object.keys(selectedReasons).length ||
              !!(counterOffer && counterOffer <= highestBid)
            }
          />
          <Button
            className="w-1/2"
            label={t('dashboard_decline_modal_cancel_btn')}
            icon={null}
            onClick={onDismiss}
            outline
          />
        </div>
      }
    />
  );
};

export default DeclineModal;
