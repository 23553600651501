import { motion, Target } from 'framer-motion';

type Props = {
  className?: string;
  exit?: Target | false;
  children: React.ReactNode;
  initial?: Target;
  animate?: Target;
};

const Fade: React.FC<Props> = ({
  className = '',
  children,
  initial,
  animate,
  exit = {},
}) => {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0, ...initial }}
      animate={{ opacity: 1, ...animate }}
      exit={exit ? { opacity: 0, ...exit } : undefined}
    >
      {children}
    </motion.div>
  );
};

export default Fade;
