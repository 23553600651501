import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'classnames';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down.svg';

type Props = {
  className?: string;
  title: string;
  questions: { title: string; content: string }[];
};

const FAQ: React.FC<Props> = ({ className, title, questions }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggle = (index: number) =>
    setOpenIndex(openIndex === index ? null : index);

  return (
    <div className={className}>
      <h1 className="px-6 text-xl font-bold">{title}</h1>
      <hr className="border-none h-[1px] bg-light mx-6 mt-6" />
      <div className="px-6">
        {questions.map(({ title, content }, index) => (
          <div
            key={index}
            className="py-3 border-b border-light cursor-pointer"
          >
            <div
              className="flex justify-between items-center"
              onClick={() => toggle(index)}
            >
              <div className="font-bold">{title}</div>
              <ChevronIcon
                className={cx(
                  'size-6 transition-transform',
                  openIndex === index && '-rotate-180'
                )}
              />
            </div>
            <AnimatePresence>
              {openIndex === index && (
                <motion.div
                  className="overflow-hidden"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  <p className="mt-2">{content}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
