import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import Button from 'components/ui/Button';
import Fade from './Fade';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

type MainProps = {
  isOpen: boolean;
  title: string;
  onDismiss: () => any;
};

export type Props = MainProps &
  (
    | {
        description: string | React.ReactNode;
        btnText: string;
        content?: never;
        footer?: never;
        onConfirm: () => any;
      }
    | {
        description?: never;
        btnText?: never;
        content: React.ReactNode;
        footer: React.ReactNode;
        onConfirm?: never;
      }
  );

const Modal: React.FC<Props> = ({
  isOpen,
  title,
  description,
  btnText = '',
  onConfirm,
  onDismiss,
  content,
  footer,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <Fade className="fixed top-0 left-0 flex items-center justify-center w-full max-screen h-screen z-50">
          <div className="absolute w-full h-full bg-[rgba(17,24,39,0.7)] z-[-1]" />
          <motion.div
            className="w-full max-w-[640px] mx-4 bg-background rounded-lg"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
          >
            <div className="flex items-center justify-between px-4 py-5">
              <h2 className="text-xl font-bold">{title}</h2>
              <CloseIcon
                className="shrink-0 ml-3 size-6 cursor-pointer"
                onClick={onDismiss}
              />
            </div>
            <div className="p-6 border-t border-b border-border-light max-h-[500px] whitespace-pre-line overflow-y-auto">
              {content ? content : <p className="html-md">{description}</p>}
            </div>
            <div className="p-6">
              {footer ? (
                footer
              ) : (
                <Button
                  className="w-full"
                  label={btnText}
                  icon={null}
                  onClick={onConfirm}
                />
              )}
            </div>
          </motion.div>
        </Fade>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
