import { Fragment } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import cx from 'classnames';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

export type Tab = { id: string; label: string; disabled: boolean };

type Props = {
  activeTab: string;
  tabs: Tab[];
  onChange?: (activeTab: string) => any;
};

const Tabs: React.FC<Props> = ({ activeTab, tabs, onChange }) => {
  const { width } = useWindowSize();

  const renderStepCircle = (tab: Tab, index: number, isMobile: boolean) => {
    const foundIndex = tabs.findIndex((el) => el.id === tab.id);
    const activeFoundIndex = tabs.findIndex((el) => el.id === activeTab);
    const divider =
      foundIndex < tabs.length - 1 ? (
        <div
          className={cx(
            'w-5 h-[2px] rounded-full',
            tabs?.[index + 1]?.disabled ? 'bg-border-light' : 'bg-success'
          )}
        />
      ) : null;

    const circleCls =
      'flex items-center justify-center shrink-0 size-6 border-2 border-success rounded-full text-sm font-bold';

    if (index < activeFoundIndex) {
      return (
        <>
          <span className={cx(circleCls, 'bg-success')}>
            <CheckIcon className="size-4" />
          </span>
          {isMobile && divider}
        </>
      );
    }

    if (!tab.disabled || index === activeFoundIndex) {
      return (
        <>
          <span className={circleCls}>{index + 1}</span>
          {isMobile && divider}
        </>
      );
    }

    return (
      <>
        <span className={cx(circleCls, '!border-border-light bg-border-light')}>
          {index + 1}
        </span>
        {isMobile && divider}
      </>
    );
  };

  if (!width) {
    return null;
  }

  if (width < 640) {
    return (
      <div className="text-center">
        <div className="flex items-center justify-center gap-x-1">
          {tabs.map((tab, i) => (
            <Fragment key={tab.id}>{renderStepCircle(tab, i, true)}</Fragment>
          ))}
        </div>
        <h2 className="mt-3 text-2xl font-bold">
          {tabs.find((el) => el.id === activeTab)?.label || ''}
        </h2>
      </div>
    );
  }

  return (
    <div className="flex border border-light overflow-x-auto">
      {tabs.map((tab, i) => (
        <div
          key={tab.id}
          className={cx(
            'relative font-bold w-1/5 min-w-[250px] p-5 pt-[27px] pb-[31px] border-r border-light last:border-0',
            onChange && 'cursor-pointer'
          )}
          onClick={() => (onChange ? onChange(tab.id) : {})}
        >
          <div className="flex items-center">
            {renderStepCircle(tab, i, false)}
            <span className="ml-2">{tab.label}</span>
          </div>
          {i !== tabs.length - 1 && (
            <>
              <div
                className="absolute top-1/2 left-full -translate-y-1/2 -translate-x-[0.3px] border-background z-[2]"
                style={{
                  borderTop: '8.5px solid transparent',
                  borderBottom: '8.5px solid transparent',
                  borderLeftWidth: 7,
                }}
              />
              <div
                className="absolute top-1/2 left-full -translate-y-1/2 border-light z-[1]"
                style={{
                  borderTop: '10px solid transparent',
                  borderBottom: '10px solid transparent',
                  borderLeftWidth: 8,
                }}
              />
            </>
          )}

          {activeTab === tab.id && (
            <motion.span
              layoutId="tabs"
              className="absolute -bottom-0 left-0 w-full h-[5px] z-[5] bg-dark"
              transition={{
                type: 'spring',
                bounce: 0,
                duration: 0.6,
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
