import cx from 'classnames';
import './Spinner.scss';

type Props = {
  className?: string;
  type?: 'dots' | 'round';
};

const Spinner: React.FC<Props> = ({ className, type = 'dots' }) => {
  switch (type) {
    case 'round':
      return <div className={cx('Spinner--round size-[40px]', className)} />;
    case 'dots':
    default:
      return (
        <div className={cx('Spinner--dots w-[60px] scale-[0.6]', className)} />
      );
  }
};
export default Spinner;
