import cx from 'classnames';

import Spinner from 'components/ui/Spinner/Spinner';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

type Props = {
  href?: string;
  className?: string;
  label: string | React.ReactNode;
  onClick?: () => any;
  icon?: React.ReactNode;
  iconSlot?: 'start' | 'end';
  isDisabled?: boolean;
  isLoading?: boolean;
  outline?: boolean;
};

const Button: React.FC<Props> = ({
  href,
  className,
  label,
  onClick,
  icon = <ArrowRightIcon className="ml-1 size-6" />,
  iconSlot = 'end',
  isDisabled = false,
  isLoading = false,
  outline = false,
}) => {
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(
          'flex items-center justify-center px-4 py-3 font-bold bg-primary border-b-2 border-primary-shade rounded transition-opacity ease-in',
          isDisabled && 'opacity-70 cursor-not-allowed',
          className
        )}
      >
        {iconSlot === 'start' ? (
          <>
            {icon}
            {label}
          </>
        ) : (
          <>
            {label}
            {icon}
          </>
        )}
      </a>
    );
  }

  return (
    <button
      className={cx(
        'relative px-4 py-3 font-bold rounded transition-opacity ease-in',
        outline
          ? 'bg-background border border-dark'
          : 'bg-primary border-b-2 border-primary-shade',
        isDisabled && '!text-border cursor-not-allowed',
        {
          '!bg-background !border-light': isDisabled && outline,
          '!bg-border-light !border !border-background': isDisabled && !outline,
        },
        className
      )}
      onClick={() => (onClick && !isLoading ? onClick() : {})}
      disabled={isDisabled}
    >
      {isLoading && (
        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner />
        </span>
      )}
      <span
        className={cx(
          'flex items-center justify-center',
          isLoading && '!invisible'
        )}
      >
        {iconSlot === 'start' ? (
          <>
            {icon}
            {label}
          </>
        ) : (
          <>
            {label}
            {icon}
          </>
        )}
      </span>
    </button>
  );
};

export default Button;
